var MantleBehavior = MantleBehavior || {};

(function ($) {
  Drupal.behaviors.basicCarouselFormatter = MantleBehavior.create({
    targetSelector: '.js-basic-carousel-formatter-v1',
    hasVideoIframes: null,
    /* eslint-disable-next-line complexity */
    attachLazyTarget: function (target) {
      var self = this;
      var $this = $(target);
      // Loop through and init the carousels.
      // Carousels might contain variations, so dynamically change the settings before constructing slick
      var $carousel = $this.find('.js-basic-carousel');
      var useResponsive = false;
      var autoplay = $carousel.data('slides-autoplay');
      var fade = $carousel.data('fade');
      var slidesToShow = parseInt($carousel.data('slides-show'));
      var slidesToScroll = parseInt($carousel.data('slides-scroll'));
      var slidesToShowMobile = parseInt($carousel.data('slides-show-mobile'));
      var slidesToScrollmobile = parseInt($carousel.data('slides-scroll-mobile'));
      var autoplaySpeed = parseInt($carousel.data('slides-autoplay-speed'));
      // Responsive dots / arrows
      var pcDotsArrowsArgs = self.checkDotsAndArrows($carousel.data('dots-arrows-pc'));
      var mobileDotsArrowsArgs = self.checkDotsAndArrows($carousel.data('dots-arrows-mobile'));
      var speed = parseInt($carousel.data('speed'));
      var peekingPC = false;
      var peekingMob = false;

      // fade settings
      if (typeof fade === 'undefined') {
        fade = false;
      } else {
        fade = true;
      }

      // peeking settings
      if ($this.hasClass('js-basic-carousel-formatter--pc_peeking')) {
        peekingPC = true;
      }
      if ($this.hasClass('js-basic-carousel-formatter--mob_peeking')) {
        peekingMob = true;
      }

      // plain with defaults
      var settingsPlain = {
        infinite: true,
        autoplay: autoplay,
        autoplaySpeed: autoplaySpeed,
        fade: fade,
        slidesToShow: slidesToShow,
        slidesToScroll: slidesToScroll,
        arrows: pcDotsArrowsArgs.arrows,
        rows: 0,
        speed: speed
      };
      // case for custom slides count and dots for mobile
      var settingsResponsive = {
        infinite: true,
        slidesToShow: slidesToShow,
        slidesToScroll: slidesToScroll,
        autoplay: autoplay,
        autoplaySpeed: autoplaySpeed,
        fade: fade,
        dots: pcDotsArrowsArgs.dots,
        arrows: pcDotsArrowsArgs.arrows,
        rows: 0,
        speed: speed,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              dots: mobileDotsArrowsArgs.dots,
              arrows: mobileDotsArrowsArgs.arrows,
              slidesToShow: slidesToShowMobile,
              slidesToScroll: slidesToScrollmobile
            }
          }
        ]
      };

      // autoplay settings
      if (typeof autoplay === 'undefined') {
        autoplay = false;
      } else {
        autoplay = true;
      }

      // slide counts
      if (slidesToShowMobile > 2) {
        useResponsive = true;
      }

      // Set transparent Fade on Peeking slides
      if (peekingPC || peekingMob) {
        $carousel.on('setPosition', function (event, _slick) {
          var currentSlide = _slick.currentSlide;
          var slidesLength = _slick.slideCount;
          var slidesToShow = _slick.getOption('slidesToShow');
          var slidesToScroll = _slick.getOption('slidesToScroll');
          var $slides = _slick.$slideTrack.children('.slick-slide');
          var lastActiveSlide = currentSlide + slidesToShow - 1;
          var remaining = currentSlide + slidesToScroll - slidesLength;

          if (remaining > 0) {
            currentSlide = currentSlide - remaining;
          }

          $slides.removeClass('slide-active');
          for (var i = currentSlide; i <= lastActiveSlide; i++) {
            $slides.filter('[data-slick-index="' + i + '"]').addClass('slide-active');
          }
        });
      }

      if (useResponsive === 'true') {
        $carousel.not('.slick-initialized').slick(settingsPlain);
      } else {
        $carousel.not('.slick-initialized').slick(settingsResponsive);
      }

      $carousel.off('.fix_lazy_images');
      $carousel.on('beforeChange.fix_lazy_images', function (event, slick, currentSlide, nextSlide) {
        var $iframe = $();

        if (self.hasVideoIframes === null) {
          // If we don't have iframes we no need to parse on ever change.
          $iframe = slick.$slides.find('.js-videojs-landing:visible ~ .js-videojs-player .video-js:not(.vjs-playing):not(.vjs-has-started) iframe');
          self.hasVideoIframes = $iframe.length;
        } else if (self.hasVideoIframes > 0) {
          $iframe = slick.$slides.find('.js-videojs-landing:visible ~ .js-videojs-player .video-js:not(.vjs-playing):not(.vjs-has-started) iframe');
        }
        /**
         * Specific fix for iPhones, iframe ignores z-index while transitioning
         * Only taget not playting iframes that have a visible landing poster sibling
         */
        if (self.hasVideoIframes > 0) {
          // Allow poster to show while tranzitioning.
          $iframe.addClass('hidden');
        }
        _mantle_carousel_check_for_lazy_errors($carousel);
      });

      $carousel.on('afterChange', function (e, slick) {
        if (!self.hasVideoIframes) {
          // If we don't have any videos, we just return.
          return;
        }
        var $iframe = slick.$slides.find('.js-videojs-landing:visible ~ .js-videojs-player iframe');

        // Allow poster to show while tranzitioning.
        $iframe.removeClass('hidden');
      });
    },
    checkDotsAndArrows: function (dotsAndArrows) {
      var dotsArrowsArgs = {};

      switch (dotsAndArrows) {
        case 'arrows':
          dotsArrowsArgs.dots = false;
          dotsArrowsArgs.arrows = true;
          break;
        case 'both':
          dotsArrowsArgs.dots = true;
          dotsArrowsArgs.arrows = true;
          break;
        case 'none':
          dotsArrowsArgs.dots = false;
          dotsArrowsArgs.arrows = false;
          break;
      }

      return dotsArrowsArgs;
    }
  });
})(jQuery);
